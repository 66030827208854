import { Input } from "antd";
import "./InputWithButton.less";
import { useEffect, useState } from "react";

const CustomForm = () => {
  const [email, setEmail] = useState<string>("");
  const [validationError, setValidationError] = useState('');
  const [status, setStatus] = useState<"success" | "sending" | "error" | undefined>()
  const submit = () => {
    if (!email) {
      setValidationError('The email field can not be empty');
      return;
    }

    if (email && email.indexOf("@") > -1 && email.indexOf(".") > -1) {
      setStatus("sending")

      fetch('/.netlify/functions/subscription', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email
        })
      }).then((res) => res.json())
        .then((res) => {
          if (res.status === 200) {
            setStatus("success")
          } else {
            setStatus("error")
            setValidationError("Something went wrong")
          }
        })
      return;
    }
    setValidationError('Please enter a valid email');
  }

  useEffect(() => {
    let timerId = setTimeout(() => {
      setValidationError('');
    }, 5000);
    return () => {
      clearTimeout(timerId);
    };
  }, [validationError]);

  useEffect(() => {
    if (status === "success") {
      setEmail("");
    }
  }, [status])

  return (
    <>
      <form
        className="form-subscribe"
        action="https://markets.us5.list-manage.com/subscribe/post?u=791035bf31901c3116b15167c&amp;id=56d6d73c59"
        method="POST"
      >
        <Input
          className="input-class"
          name="EMAIL" placeholder="email@example.com"
          type="email" onChange={ (v) => setEmail(v.target.value) }
          value={ email }
        />
        <div
          className={`button button-transparent secondary-button form-button ${status === 'success' ? 'form-button-disabled' : ''}`}
          onClick={submit}
        >Subscribe</div>
      </form>
      { status === "sending" &&
      <div className="notice-style" style={ {color: "white"} }>sending...<br/></div> }
      { !!validationError && (
        <div className="notice-style"
             onClick={ () => setValidationError('') }
        >
          {validationError}
        </div>
      ) }
      { status === "success" && (
        <div className="notice-success">
            Thank you! Your submission has been received!
        </div>
      ) }
    </>
  );
};

export const InputWithButton = () => {
  return (
    <>
      <p className="form-title">Subscribe to the announcements</p>
      <CustomForm />
    </>
  );
};
