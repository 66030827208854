import React, { useEffect, Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const ScrollToTop: React.FunctionComponent<RouteComponentProps> = ({ history, children }) => {
  useEffect(() => {
    const appEl = document.querySelector('.App');
    
    const unlisten = history.listen(() => {
      if(appEl) appEl.scrollTo(0, 0);
    });
    
    return () => {
      unlisten();
    }
  }, [history]);

  return (<Fragment>{children}</Fragment>);
}

export default withRouter(ScrollToTop);