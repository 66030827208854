import React from "react";
import update from "../../assets/update-small.png";
import updateWebp from "../../assets/update-small.webp";


function formatDate(date: string): string {
  const d = new Date(date);
  return Intl.DateTimeFormat('en-US', {month:"2-digit", day:"2-digit", year:"numeric"} ).format(d).replaceAll('/','.');

}

// TODO: Add types
// @ts-ignore
export const BlogPost = ({ post, index }) => {
  const { title, excerpt, url, published_at, feature_image } = post;

  return (
    <>
      <div className="Card-class eighth-single-card">
        <div className="eighth-single-card-image">
          {feature_image
            ? <img alt="icon" src={feature_image} loading="lazy" />
            : <picture>
              <source srcSet={updateWebp} type="image/webp"  />
              <source srcSet={update} type="image/png" />
              <img src={update} alt="icon" loading="lazy" />
            </picture>
          }
        </div>
        <p className="eighth-date">
          {published_at && formatDate(published_at)}
        </p>
        <p className="eighth-title">
          {title}
        </p>
        <p className="eighth-description">
          {excerpt}
        </p>
        <div className="eighth-button">
          {url && <a href={url} className="button button-transparent secondary-button"
                          target="_blank" rel="noreferrer">Learn more</a>}
        </div>
      </div>
      {index < 2 && <div className="update-between" />}
    </>
  )
};
