import { useHistory } from "react-router-dom";
import "./IDOBanner.less";

function IDOBanner() {
  const history = useHistory();
  return (
    <div className="banner">
        <p className="banner-text">Ready to go pre-launch!</p>
        <button className="banner-btn" onClick={() => history.push('whitelist')}>Apply to Whitelist</button>
    </div>
  );
}

export default IDOBanner;
