import { Button } from "antd";
import "./SampleButton.less";

export interface SampleButtonInterface {
  text: String;
  tolink?: string;
  external?: Boolean;
  widthButton?: string
};


export const SampleButton = ({text, tolink, external = false, widthButton}: SampleButtonInterface) => {
  const target = external ? '_blank' : '_self';

  function goLink() {
    if (tolink !== undefined)
      window.open(tolink, target);
  }

  return (
    <div>
      <Button className="sample-button" style={{width: widthButton ? widthButton : ''}} onClick={ goLink }>{ text }</Button>
    </div>
  );
};
