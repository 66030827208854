import { SampleButton } from "../SampleButton/SampleButton";
import "./EmptyBlock.less";

const EmptyBlock = ({ title, descriptionUp, descriptionDown, buttonText, widthButton, isBlankPage }: {
  title: string,
  descriptionUp: string,
  descriptionDown: string,
  buttonText: string,
  widthButton: string,
  isBlankPage: boolean
}) => {
  return (
    <div className="empty">
      <div className={`empty-container ${isBlankPage && 'blank'}`}>
        <div className="empty-text">
          <p className="empty-title">{title}</p>
          <p className="empty-description">{descriptionUp}</p>
          <p className="empty-description empty-description-down">{descriptionDown}</p>
          <SampleButton text={buttonText} widthButton={widthButton} tolink="/" />
        </div>
        <div className={`empty-bg ${isBlankPage ? 'bg-blank' : 'bg-404'}`} />
        <div className="empty-shadow" />
        <div className="empty-purple-bg" />
        </div>
      </div>
  );
};

export default EmptyBlock;
