import React, { FC } from 'react';
import { Tooltip as AntTooltip } from 'antd';
import type { TooltipProps as AntTooltipProps } from 'antd';
import c from 'classnames';
import './HubbleTooltip.less';

export const HubbleTooltip: FC<AntTooltipProps> = ({ children, ...props }) => {
  const classNames = c('hubble-tooltip', props.overlayClassName);

  return (
    <AntTooltip {...props} overlayClassName={classNames}>
      {children}
    </AntTooltip>
  );
};
