import './Team.less';
import Footer from '../../Components/Footer/Footer';

const Team = () => {
  return (
    <div className="team team-container">
      <div className="team-block-container">
        <div className="title-block">
          <span className="title-upper-block">Meet the contributors</span>

          <span className="title-lower-block">
            If you’d like to chat, join the{' '}
            <a href="https://discord.com/invite/UMFdXJ8MPm" target="_blank" rel="noreferrer">
              <span className="teammate-card-tagname">Discord server</span>{' '}
            </a>{' '}
            and give us a shout. {'\n'}Or check out the{' '}
            <a href="https://twitter.com/HubbleProtocol" target="_blank" rel="noreferrer">
              <span className="teammate-card-tagname">Twitter</span>
            </a>{' '}
            account.
          </span>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Team;
