import Slider from "react-slick";
import "./Slider.less";
import update from "../../assets/update-small.png";
import updateWebp from "../../assets/update-small.webp";

function formatDate(date: string): string {
  const d = new Date(date);
  return `${d.getMonth()}.${d.getDate()}.${d.getFullYear()}`;

}

const ShowSlider = (props: ShowSliderProps) => {
  const { posts } = props;
  const settings = {
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    variableWidth: true,
    className: "slider variable-width",
  };
  return (
    <div className="slider">
      <Slider {...settings}>
        {posts.map((post, index) => {
          if (index > 2) {
            return null;
          }
          return (
            <div key={post.id} className="Card-class eighth-single-card">

              {post.feature_image
                ? <img alt="icon" src={post.feature_image} />
                : <picture>
                  <source srcSet={updateWebp} type="image/webp" />
                  <source srcSet={update} type="image/png" />
                  <img src={update} alt="icon" loading="lazy" />
                </picture>
              }
              <p className="eighth-date">
                {post.published_at && formatDate(post.published_at)}
              </p>
              <p className="eighth-title">
                {post.title}
              </p>
              <p className="eighth-description">
                {post.excerpt}
              </p>
              <div className="eighth-button">
                {post.url && <a href={post.url} className="button button-transparent secondary-button"
                          target="_blank" rel="noreferrer">Learn more</a>}
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

interface ShowSliderProps {
  posts: any[]
}

export default ShowSlider;
