import './MediaLogosSection.less';
import BenzingaLogo from "../../assets/Benzinga.svg";
import IbtLogo from "../../assets/ibt.svg";
import BitcoinlistLogo from "../../assets/bitcoinlist.svg";
import BusinessInsiderLogo from "../../assets/business-insider.svg";
import BtcManagerLogo from "../../assets/btc-manager.svg";
import CoinMarketCapLogo from "../../assets/coin-market-cap.svg";
import CoinSpeakLogo from "../../assets/coinspeak.svg";
import CoinTelegraphLogo from "../../assets/cointelegraph.svg";
import CurrencyLogo from "../../assets/currency.svg";
import InvestingLogo from "../../assets/investing.svg";
import NewsBtcLogo from "../../assets/news-BTC.svg";
import YahooLogo from "../../assets/yahoo.svg";

const MediaLogosSection = () => {

  return (
    <div className="every-container container-bg-logos">
      <div className='bg-blue' />
      <div className="fifth-container fifth-content-container media-section-container">
        <div className="fifth-content-container">
          <p className="fifth-container-title">Featured In:</p>
          <div className="fifth-investors">
            <a
              href="https://markets.businessinsider.com/news/stocks/hubble-protocol-unpacks-major-issue-for-developers-at-solanas-breakpoint-conference-10711731"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between">
                <img
                  className="logo-without-text"
                  src={BusinessInsiderLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
            <a
              href="https://www.yahoo.com/now/hubble-protocol-nets-3-6m-114200803.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAHE3qcf1E6OGfUD_YHZjECIxWcDLINZOjVnKQR7ciwWT1Hi8ksoRf40Y7ZLqnX3yI384Fy_nyXk3j8qZpS5Feksmxrfmss-HvOEpaagb21M6ROkNUkdShzmzBsXndCrLq9Wh5voLvS1r4WE6Rv6g_TWnwfsoy2H5pRg5z2eq4oY5"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between investor-between-mobile">
                <img
                  className="logo-without-text"
                  src={YahooLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
            <a
              href="https://www.investing.com/news/cryptocurrency-news/hubble-protocol-raises-36m-in-preido-event-to-support-its-zerointerest-defi-platform-2716907"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between ">
                <img
                  className="logo-without-text"
                  src={InvestingLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
            <a
              href="https://bitcoinist.com/solana-breakpoint-conference-coverage-hubble-protocol-shines-bright/"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between investor-between-mobile">
                <img
                  className="logo-without-text"
                  src={BitcoinlistLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
            <a
              href="https://www.ibtimes.com/fundraise-roundup-next-wave-blockchain-startups-3359058"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between">
                <img
                  className="logo"
                  src={IbtLogo}
                  alt="jump"
                  loading="lazy"
                />
                <p className="indents-logo">
                  International <br />
                  Business Times{" "}
                </p>
              </div>
            </a>
            <a
              href="https://btcmanager.com/hubble-protocol-3-6-million-veteran-crypto-venture-funds-axie-infinity-investor/"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between investor-between-mobile">
                <img
                  className="logo"
                  src={BtcManagerLogo}
                  alt="jump"
                  loading="lazy"
                />
                <p>
                  BTC <br />
                  Manager
                </p>
              </div>
            </a>
            <a
              href="https://www.newsbtc.com/news/company/hubble-protocol-unpacks-major-issue-for-developers-at-solanas-breakpoint-conference/"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between">
                <img
                  className="logo-without-text"
                  src={NewsBtcLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
            <a
              href="https://cointelegraph.com/press-releases/hubble-nets-36m-funding-to-develop-solanas-defi-and-stablecoin-hub"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between investor-between-mobile">
                <img
                  className="logo-without-text"
                  src={CoinTelegraphLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
            <a
              href="https://currency.com/hubble-blockchain-protocol-combines-financial-primitives"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between">
                <img
                  className="logo-without-text"
                  src={CurrencyLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
            <a
              href="https://www.benzinga.com/markets/cryptocurrency/21/11/24169322/solana-spotlights-rising-defi-star-hubble-protocol-at-breakpoint-lisbon"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between investor-between-mobile">
                <img
                  className="logo-without-text"
                  src={BenzingaLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
            <a
              href="https://coinmarketcap.com/headlines/news/hubble-protocol-unpacks-major-issue-for-developers-at-solanas-breakpoint-conference/"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between">
                <img
                  className="logo"
                  src={CoinMarketCapLogo}
                  alt="jump"
                  loading="lazy"
                />
                <p>CoinMarketCap</p>
              </div>
            </a>
            <a
              href="https://www.coinspeaker.com/hubble-protocol-launches-public-devnet-and-rewards-program-ahead-of-ido/"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between">
                <img
                  className="logo-without-text"
                  src={CoinSpeakLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
          </div>

          <div className="media-mobile-container">
            <a
              href="https://btcmanager.com/hubble-protocol-3-6-million-veteran-crypto-venture-funds-axie-infinity-investor/"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between">
                <img
                  className="logo"
                  src={BtcManagerLogo}
                  alt="jump"
                  loading="lazy"
                  style={{width: '28px', height: '26px'}}
                />
                <p className="text-logo">
                  BTC <br />
                  Manager
                </p>
              </div>
            </a>
            <a
              href="https://bitcoinist.com/solana-breakpoint-conference-coverage-hubble-protocol-shines-bright/"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between investor-between-mobile">
                <img
                  className="logo-without-text"
                  src={BitcoinlistLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
            <a
              href="https://www.ibtimes.com/fundraise-roundup-next-wave-blockchain-startups-3359058"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between">
                <img
                  className="logo"
                  src={IbtLogo}
                  alt="jump"
                  loading="lazy"
                />
                <p className="indents-logo text-logo">
                  International <br />
                  Business Times{" "}
                </p>
              </div>
            </a>
            <a
              href="https://www.yahoo.com/now/hubble-protocol-nets-3-6m-114200803.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAHE3qcf1E6OGfUD_YHZjECIxWcDLINZOjVnKQR7ciwWT1Hi8ksoRf40Y7ZLqnX3yI384Fy_nyXk3j8qZpS5Feksmxrfmss-HvOEpaagb21M6ROkNUkdShzmzBsXndCrLq9Wh5voLvS1r4WE6Rv6g_TWnwfsoy2H5pRg5z2eq4oY5"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between investor-between-mobile">
                <img
                  className="logo-without-text"
                  src={YahooLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
            <a
              href="https://currency.com/hubble-blockchain-protocol-combines-financial-primitives"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between">
                <img
                  className="logo-without-text"
                  src={CurrencyLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
            <a
              href="https://www.benzinga.com/markets/cryptocurrency/21/11/24169322/solana-spotlights-rising-defi-star-hubble-protocol-at-breakpoint-lisbon"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between investor-between-mobile">
                <img
                  className="logo-without-text benzinga-logo"
                  src={BenzingaLogo}
                  alt="jump"
                  loading="lazy"
                  style={{
                    maxWidth: '104px'
                  }}
                />
              </div>
            </a>
            <a
              href="https://coinmarketcap.com/headlines/news/hubble-protocol-unpacks-major-issue-for-developers-at-solanas-breakpoint-conference/"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between">
                <img
                  className="logo"
                  src={CoinMarketCapLogo}
                  alt="jump"
                  loading="lazy"
                />
                <p className="text-logo">CoinMarketCap</p>
              </div>
            </a>
            <a
              href="https://www.investing.com/news/cryptocurrency-news/hubble-protocol-raises-36m-in-preido-event-to-support-its-zerointerest-defi-platform-2716907"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between investor-between-mobile">
                <img
                  className="logo-without-text"
                  src={InvestingLogo}
                  alt="jump"
                  loading="lazy"
                  style={{
                    maxWidth: '104px'
                  }}
                />
              </div>
            </a>
            <a
              href="https://www.newsbtc.com/news/company/hubble-protocol-unpacks-major-issue-for-developers-at-solanas-breakpoint-conference/"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between">
                <img
                  className="logo-without-text"
                  src={NewsBtcLogo}
                  alt="jump"
                  loading="lazy"
                  style={{
                    maxWidth: '104px'
                  }}
                />
              </div>
            </a>
            <a
              href="https://www.coinspeaker.com/hubble-protocol-launches-public-devnet-and-rewards-program-ahead-of-ido/"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between investor-between-mobile">
                <img
                  className="logo-without-text coinspeak-logo"
                  src={CoinSpeakLogo}
                  alt="jump"
                  loading="lazy"
                  style={{
                    maxWidth: '104px'
                  }}
                />
              </div>
            </a>
            <a
              href="https://cointelegraph.com/press-releases/hubble-nets-36m-funding-to-develop-solanas-defi-and-stablecoin-hub"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between">
                <img
                  className="logo-without-text"
                  src={CoinTelegraphLogo}
                  alt="jump"
                  loading="lazy"
                />
              </div>
            </a>
            <a
              href="https://markets.businessinsider.com/news/stocks/hubble-protocol-unpacks-major-issue-for-developers-at-solanas-breakpoint-conference-10711731"
              target="_blank"
              rel="noreferrer"
              className="media-container-link"
            >
              <div className="investor-container investor-between investor-between-mobile">
                <img
                  className="logo-without-text"
                  src={BusinessInsiderLogo}
                  alt="jump"
                  loading="lazy"
                  style={{
                    maxWidth: '76px'
                  }}
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaLogosSection;
