import { Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import Head from '../../Components/Head/Head';
import Footer from '../../Components/Footer/Footer';
import ShowSlider from '../../Components/Slider/Slider';
import { ReactComponent as HBB } from '../../assets/hbb.svg';
import { ReactComponent as Users } from '../../assets/users_purple.svg';
import { ReactComponent as Threecircle } from '../../assets/threecircle_purple.svg';
import { ReactComponent as Dollar } from '../../assets/dollar_purple.svg';
import { ReactComponent as Hubble } from '../../assets/hubble_purple.svg';
import { ReactComponent as Threeicon } from '../../assets/threeicon.svg';
import { ReactComponent as Zeropro } from '../../assets/zeropro.svg';
import { ReactComponent as Eleventime } from '../../assets/eleventime.svg';
import { ReactComponent as Goup } from '../../assets/goup.svg';
import LogoContent from '../../assets/logo.svg';
import MobileLogoContent from '../../assets/main-logo.png';

import KudelskiLogo from '../../assets/kudelski-logo-2.svg';
import ArcadiaLogo from '../../assets/arcadia-logo.svg';
import SmartStateLogo from '../../assets/smart-state-logo.svg';


import MulticoinLogo from '../../assets/investors_logos/multicoin.svg';
import JumpLogo from '../../assets/JC.png';
import HushLogo from '../../assets/hush.png';
import AllianceLogo from '../../assets/investors_logos/alliance.svg';
import SolanaVenturesLogo from '../../assets/solana-ventures-logo.svg';
import SpartanLogo from '../../assets/spartan-logo.png';
import DelphiLogo from '../../assets/delphi-logo.png';

import MechanismLogoX05 from '../../assets/investors_logos/mechanism_capital/MechanismCapital 0.5x.png';
import MechanismLogoX1 from '../../assets/investors_logos/mechanism_capital/MechanismCapital 1x.png';
import MechanismLogoX15 from '../../assets/investors_logos/mechanism_capital/MechanismCapital 1.5x.png';
import MechanismLogoX2 from '../../assets/investors_logos/mechanism_capital/MechanismCapital 2x.png';

import BlockChange from '../../assets/investors_logos/without_text/BlockChange.svg';
import Cumberland from '../../assets/investors_logos/without_text/Cumberland.svg';
import DigitalCurrencyGroup from '../../assets/investors_logos/without_text/DigitalСurrencyGroup.svg';
import ParafiCapital from '../../assets/investors_logos/ParafiCapital.svg';
import AgeLogo from '../../assets/investors_logos/AgeLogo.svg';

import './Content.less';
import '../../Components/SampleButton/SampleButton.less';
import ogImg from '../../assets/og-landing.png';
import { BlogPost } from '../../Components/BlogPost/BlogPost';
import AnimationScrollVideo from '../../Components/AnimationScrollVideo/AnimationScrollVideo';
import TextAboutHubble from '../../Components/TextAboutHubble/TextAboutHubble';
import AnimationImage from '../../Components/AnimationImage/AnimationImage';
import { useScrollDirection } from '../../hooks/useScrollDirection';
import MediaLogosSection from '../../Components/MediaLogosSection/MediaLogosSection';
import { InputWithButton } from '../../Components/InputWithButton/InputWithButton';
import { HubbleTooltip } from '../../Components/HubbleTooltip';
import { QuestionCircleFilled } from '@ant-design/icons';

const MainContent = () => {
  const animationBlock: any = useRef(null);

  const directionScroll = useScrollDirection();
  const [posts, setPost] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isHideShadowBg, setHideShadowBg] = useState(false);
  const [windowInnerHeight, setWindowInnerHeight] = useState<number>(0);
  const isTabletWindowWidth = true;

  const [isDownAnimationVideoWorked, setIsDownAnimationVideoWorked] = useState(false);
  const [isUpAnimationVideoWorked, setIsUpAnimationVideoWorked] = useState(true);
  const [isStartUpAnimationVideoWorked, setIsStartUpAnimationVideoWorked] = useState(false);

  const headData = {
    siteName: 'Hubble Protocol',
    title: 'Hubble Protocol',
    url: 'https://hubbleprotocol.io/',
    description: 'Exploring the DeFi Universe on Solana',
    imageUrl: ogImg || '',
    imageAlt: 'Hubble Protocol',
    type: 'website',
    pageTitle: 'Hubble Protocol | $USDH Stablecoin Protocol on Solana DeFi',
    pageDescription:
      'Hubble is the decentralized Solana Protocol behind $USDH Stablecoin. Maximize your Cryptos strategies across Solana DeFi. USDH is Overcollateralized (+100%) by major Solana Assets: Solend cTokens, mSOL Marinade, stSOL Lido, BTC, SOL, ETH, and more.',
  };

  const auditedTooltipTitle = (
    <span>
      Hubble is committed to strict security standards and has 4 audits so far, however as more features are being
      deployed, there are parts of the contracts that have not been fully audited yet.{' '}
      <a
        href="https://docs.hubbleprotocol.io/documentation/security-audits"
        target="_blank"
        rel="noreferrer"
        className="tooltip-link"
      >
        Please read here
      </a>
      .
    </span>
  );

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    async function getPost() {
      await fetch('https://hubble.ghost.io/ghost/api/v3/content/posts/?key=befbd970317b19f174b77e4502')
        .then((res) => res.json())
        .then((data) => setPost(data.posts))
        .catch((err) => console.log(err));
    }

    getPost();
  }, []);

  useEffect(() => {
    setWindowInnerHeight(window.innerHeight - 200);
  }, []);

  return (
    <div>
      <Head {...headData} />

      <div className={`shadow-bg ${isHideShadowBg && 'hide'}`} />

      <div
        className={`${
          !isDownAnimationVideoWorked && !isTabletWindowWidth ? 'down-animation-block' : 'up-animation-block'
        }`}
        ref={animationBlock}
      >
        <div
          style={{
            transform:
              (!isStartUpAnimationVideoWorked && isDownAnimationVideoWorked) ||
              (!isStartUpAnimationVideoWorked && !isUpAnimationVideoWorked)
                ? `translateY(-${windowInnerHeight}px)`
                : 'translateY(0)',
            transition: 'all 3s ease',
          }}
        >
          <div
            className={`first-overflow ${
              !isStartUpAnimationVideoWorked && isDownAnimationVideoWorked && 'first-container-hide'
            }`}
          >
            <div className="every-container">
              <div className={`first-content-container`}>
                <p className="first-container-title">
                  Supercharge Your <br />
                  Liquidity On Solana
                </p>
                <p className="first-container-description">
                  Mint USDH against multiple types of collateral. <br />
                  Repay whenever you want.
                </p>

                <div className="form-container">
                  <InputWithButton />
                </div>
              </div>
            </div>
          </div>

          <AnimationScrollVideo
            isDownAnimationVideoWorked={isDownAnimationVideoWorked}
            setIsDownAnimationVideoWorked={setIsDownAnimationVideoWorked}
            isUpAnimationVideoWorked={isUpAnimationVideoWorked}
            setIsUpAnimationVideoWorked={setIsUpAnimationVideoWorked}
            setIsStartUpAnimationVideoWorked={setIsStartUpAnimationVideoWorked}
            directionScroll={directionScroll}
            isMobile={isTabletWindowWidth}
          />

          <TextAboutHubble />

          <AnimationImage directionScroll={directionScroll} />

          <div>
            <div className="bg-purple-mobile">
              <div className="every-container">
                <div className="forth-container">
                  <div className="bg-purple" />
                  <div className="flex-container">
                    <div className="forth-content-container">
                      <p>
                        We started as a project in the Solana Hackathon. Now supported by top players in the Solana
                        ecosystem.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="every-container fifth-translate">
                <div className="fifth-container fifth-content-container">
                  <div className="fifth-content-container">
                    <p className="fifth-container-title">Backed by the Best</p>
                    <div className="fifth-investors">
                      <div className="fifth-investors-loop-left">
                        <div className="fifth-investors-list">
                          <div className="investor-container investor-between">
                            <img className="logo" src={MulticoinLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Multicoin <br />
                              Capital
                            </p>
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo" src={JumpLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Jump <br />
                              Capital
                            </p>
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo" src={HushLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              LongHash <br />
                              Ventures
                            </p>
                          </div>

                          <div className="investor-container investor-between">
                            <img className="logo" src={AllianceLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Alliance
                            </p>
                          </div>

                          <div className="investor-container investor-between">
                            <img className="logo" src={SolanaVenturesLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Solana <br />
                              Ventures
                            </p>
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo" src={SpartanLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Spartan <br />
                              Group
                            </p>
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo" src={DelphiLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Delphi <br />
                              Digital
                            </p>
                          </div>
                          <div className="investor-container investor-between">
                            <img
                              className="logo-without-text-png"
                              srcSet={`${MechanismLogoX05} 300w,
                              ${MechanismLogoX1} 768w,
                              ${MechanismLogoX15} 1024w,
                              ${MechanismLogoX2} 1280w`}
                              alt="jump"
                              loading="lazy"
                            />
                          </div>
                        </div>
                        <div className="fifth-investors-list">

                          <div className="investor-container investor-between">
                            <img className="logo" src={MulticoinLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Multicoin <br />
                              Capital
                            </p>
                          </div>

                          <div className="investor-container investor-between">
                            <img className="logo" src={JumpLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Jump <br />
                              Capital
                            </p>
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo" src={HushLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              LongHash <br />
                              Ventures
                            </p>
                          </div>

                          <div className="investor-container investor-between">
                            <img className="logo" src={AllianceLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Alliance
                            </p>
                          </div>

                          <div className="investor-container investor-between">
                            <img className="logo" src={SolanaVenturesLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Solana <br />
                              Ventures
                            </p>
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo" src={SpartanLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Spartan <br />
                              Group
                            </p>
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo" src={DelphiLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Delphi <br />
                              Digital
                            </p>
                          </div>
                          <div className="investor-container investor-between">
                            <img
                              className="logo-without-text-png"
                              srcSet={`${MechanismLogoX05} 300w,
                              ${MechanismLogoX1} 768w,
                              ${MechanismLogoX15} 1024w,
                              ${MechanismLogoX2} 1280w`}
                              alt="jump"
                              loading="lazy"
                            />
                          </div>
                        </div>
                        <div className="fifth-investors-list">
                          <div className="investor-container investor-between">
                            <img className="logo" src={MulticoinLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Multicoin <br />
                              Capital
                            </p>
                          </div>

                          <div className="investor-container investor-between">
                            <img className="logo" src={JumpLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Jump <br />
                              Capital
                            </p>
                          </div>

                          <div className="investor-container investor-between">
                            <img className="logo" src={HushLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              LongHash <br />
                              Ventures
                            </p>
                          </div>

                          <div className="investor-container investor-between">
                            <img className="logo" src={AllianceLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Alliance
                            </p>
                          </div>

                          <div className="investor-container investor-between">
                            <img className="logo" src={SolanaVenturesLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Solana <br />
                              Ventures
                            </p>
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo" src={SpartanLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Spartan <br />
                              Group
                            </p>
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo" src={DelphiLogo} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Delphi <br />
                              Digital
                            </p>
                          </div>
                          <div className="investor-container investor-between">
                            <img
                              className="logo-without-text-png"
                              srcSet={`${MechanismLogoX05} 300w,
                              ${MechanismLogoX1} 768w,
                              ${MechanismLogoX15} 1024w,
                              ${MechanismLogoX2} 1280w`}
                              alt="jump"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="fifth-investors-loop-right">
                        <div className="fifth-investors-list">
                          <div className="investor-container investor-between">
                            <img className="logo-without-text-think" src={BlockChange} alt="jump" loading="lazy" />
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo-without-text-think" src={AgeLogo} alt="jump" loading="lazy" />
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo-without-text-think" src={Cumberland} alt="jump" loading="lazy" />
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo-without-text" src={DigitalCurrencyGroup} alt="jump" loading="lazy" />
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo extra-margin" src={ParafiCapital} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Parafi
                              <br />
                              Capital
                            </p>
                          </div>
                        </div>
                        <div className="fifth-investors-list">
                          <div className="investor-container investor-between">
                            <img className="logo-without-text-think" src={BlockChange} alt="jump" loading="lazy" />
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo-without-text-think" src={AgeLogo} alt="jump" loading="lazy" />
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo-without-text-think" src={Cumberland} alt="jump" loading="lazy" />
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo-without-text" src={DigitalCurrencyGroup} alt="jump" loading="lazy" />
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo extra-margin" src={ParafiCapital} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Parafi
                              <br />
                              Capital
                            </p>
                          </div>
                        </div>
                        <div className="fifth-investors-list">
                          <div className="investor-container investor-between">
                            <img className="logo-without-text-think" src={BlockChange} alt="jump" loading="lazy" />
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo-without-text-think" src={AgeLogo} alt="jump" loading="lazy" />
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo-without-text-think" src={Cumberland} alt="jump" loading="lazy" />
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo-without-text" src={DigitalCurrencyGroup} alt="jump" loading="lazy" />
                          </div>
                          <div className="investor-container investor-between">
                            <img className="logo extra-margin" src={ParafiCapital} alt="jump" loading="lazy" />
                            <p className="indents-logo">
                              Parafi
                              <br />
                              Capital
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="every-container">
              <div className="sixth-container max-width">
                <div className="container-title">
                  Here are the features <br />
                  we’re proud of
                </div>
                <div className="sixth-content-container">
                  <div className="Card-class" style={{ overflow: 'hidden' }}>
                    <div className="sixth-container-card bg-green-box">
                      <Zeropro className="img" />
                      <p className="sixth-container-title">Zero-cost Possibilities</p>
                      <p className="sixth-container-description">
                        Hubble lets users borrow USDH for a one-time 0.5% fee and low interest rates. With the interest
                        yield on deposits, your collateral value can grow to negate fees.
                      </p>
                    </div>
                  </div>
                  <div className="investor-between" />
                  <div className="Card-class" style={{ overflow: 'hidden' }}>
                    <div className="sixth-container-card bg-white-box">
                      <Threeicon className="img" />
                      <p className="sixth-container-title">Multi-Asset Collateral</p>
                      <p className="sixth-container-description">
                        Deposit a variety of assets on Hubble, raise your collateral ratio, and unlock the liquidity in
                        your wallet.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="top-investor-between" />
                <div className="sixth-content-container">
                  <div className="Card-class" style={{ overflow: 'hidden' }}>
                    <div className="sixth-container-card bg-yellow-box">
                      <Goup className="img" />
                      <p className="sixth-container-title">Get Yield on Deposits</p>
                      <p className="sixth-container-description">
                        While your collateral is deposited, delegate it to earn the highest yield available in the
                        Solana ecosystem.
                      </p>
                    </div>
                  </div>
                  <div className="investor-between" />
                  <div className="top-investor-between" />
                  <div className="Card-class" style={{ overflow: 'hidden' }}>
                    <div className="sixth-container-card bg-blue-box">
                      <Eleventime className="img" />
                      <p className="sixth-container-title">Get up to 11x Leverage</p>
                      <p className="sixth-container-description">
                        Hubble’s capital-efficient 110% collateral ratio lets users leverage up to 11x on their
                        deposits.{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="every-container"
          style={{
            marginTop:
              (!isStartUpAnimationVideoWorked && isDownAnimationVideoWorked) ||
              (!isStartUpAnimationVideoWorked && !isUpAnimationVideoWorked)
                ? `-${windowInnerHeight}px`
                : '0',
            transition: 'all 3s ease',
          }}
        >
          <div className="seventh-container">
            <div className="seventh-container-logo">
              <div className="seventh-content-container-logo">
                <img src={LogoContent} className="logo" alt="logo" loading="lazy" />
                <img src={MobileLogoContent} className="logo-mobile" alt="logo" loading="lazy" />
                <p className="seventh-container-title">HBB Tokenomics</p>
                <p className="seventh-container-description">
                  Hubble’s native token, HBB, is a utility token designed to improve liquidity for USDH and incentivise
                  behaviours to keep USDH at peg.
                </p>
                <div className="secondary-btn-container">
                  <a
                    href="https://docs.hubbleprotocol.io/"
                    className="button button-transparent secondary-button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>
                </div>
                <div className="audit-title-container">
                  <div className="seventh-container-title-audited">
                    <span>Audited by:</span>
                    <HubbleTooltip placement="right" className="audit-tooltip" title={auditedTooltipTitle}>
                      <QuestionCircleFilled className="audit-tooltip-icon" />
                    </HubbleTooltip>
                  </div>
                </div>
                <div className="seventh-container-logo-audited">
                  <img src={KudelskiLogo} className="audit-logo" alt="logo" loading="lazy" />
                  <img src={ArcadiaLogo} className="audit-logo" alt="logo" loading="lazy" />
                  <img src={SmartStateLogo} className="audit-logo" alt="logo" loading="lazy" />
                </div>
              </div>
            </div>
            <div className="seventh-container-icon">
              <div className="Card-class">
                <div className="seventh-container-card">
                  <HBB className="img" />
                  <p className="seventh-container-card-title">Mint USDH</p>
                  <p className="seventh-container-card-description">
                    Our native stablecoin maintains its peg via open market arbitrage, demand from liquidations, a Peg
                    Stability Module along with deep market liquidity.{' '}
                  </p>
                </div>
              </div>
              <div className="investor-between" />
              <div className="Card-class">
                <div className="seventh-container-card">
                  <Users className="img" />
                  <p className="seventh-container-card-title">Governance</p>
                  <p className="seventh-container-card-description">
                    Hubble will operate as a Decentralized Autonomous Organization (DAO) with a community-supported
                    governance model. HBB holders lead the way.
                  </p>
                </div>
              </div>
              <div className="Card-class">
                <div className="seventh-container-card">
                  <Threecircle className="img" />
                  <p className="seventh-container-card-title">Fee-Sharing</p>
                  <p className="seventh-container-card-description">
                    Part of the fees from USDH issuance are directed to the HBB staking vault.{' '}
                  </p>
                </div>
              </div>
              <div className="investor-between" />
              <div className="Card-class">
                <div className="seventh-container-card">
                  <Dollar className="img" />
                  <p className="seventh-container-card-title">Liquidation Gains</p>
                  <p className="seventh-container-card-description">
                    Liquidations are democratized so anyone can participate in maintaining the health of Hubble by
                    depositing USDH in our Stability Pool. USDH is exchanged for liquidated tokens that, depending on
                    market conditions, might be worth more than the original pledged USDH at the time of the
                    liquidation.
                  </p>
                </div>
              </div>
              <div className="Card-class">
                <div className="seventh-container-card">
                  <Hubble className="img" />
                  <p className="seventh-container-card-title">HBB Emissions</p>
                  <p className="seventh-container-card-description">
                    When staking USDH in the Stability Pool, you will earn HBB as reward on top of liquidation gains.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MediaLogosSection />

        <div className="every-container" style={{ overflowX: 'hidden' }}>
          <div className="eighth-container">
            <div className="eighth-container-title">
              <p className="eighth-main-title">Recent News</p>
              <a
                href="https://blog.hubbleprotocol.io/"
                className="button button-inverse eighth-link"
                target="_blank"
                rel="noreferrer"
              >
                Discover Hubble
              </a>
            </div>
            <div className="eighth-card-container">
              {posts.map((post, index) => index < 3 && <BlogPost key={post['id']} index={index} post={post} />)}
            </div>
            <div className="eighth-card-small">{!!posts.length && <ShowSlider posts={posts} />}</div>
            <a
              href="https://blog.hubbleprotocol.io/"
              className="button button-inverse eighth-link eighth-link-mobile"
              target="_blank"
              rel="noreferrer"
            >
              Discover Hubble
            </a>
          </div>
        </div>
        <Footer setHideShadowBg={setHideShadowBg} />
      </div>
      <Modal title="From Hubble" visible={isModalVisible} onCancel={handleCancel}>
        <p>We're also super excited to live, but we're not quite there yet, come back in a month</p>
      </Modal>
    </div>
  );
};

export default MainContent;
