import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import ogImg from "../../assets/og-careers.png";
import EmptyBlock from "../../Components/EmptyBlock/EmptyBlock";

const headData = {
  siteName: "Hubble Protocol",
  title: "Hubble Protocol",
  url: "https://hubbleprotocol.io/careers",
  description: "Exploring the DeFi Universe on Solana",
  imageUrl: ogImg || "",
  imageAlt: "Hubble Markets",
  type: "website",
};

const Blank = () => {

  return (
    <>
      <Head {...headData} />

      <EmptyBlock
        title="Don’t worry"
        descriptionUp="This page is currently in work."
        descriptionDown=" We’ll upload it very soon, stay tuned!"
        buttonText="Check out landing instead"
        widthButton="248px"
        isBlankPage={true}
      />

      <Footer />
    </>
  );
};

export default Blank;
