import React, { FC, useState } from 'react';
import './Header.less';
import { ReactComponent as Twitter } from '../../assets/Twitter.svg';
import { ReactComponent as Telegram } from '../../assets/Telegram.svg';
import { ReactComponent as Discord } from '../../assets/Discord.svg';
import { ReactComponent as Blog } from '../../assets/Blog.svg';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { ReactComponent as Arrow } from '../../assets/Arrow.svg';
import Logo from '../../assets/main-logo.svg';
import LogoMobile from '../../assets/logo.svg';
import IDOBanner from '../IDOBanner/IDOBanner';

const aboutItems = [
  {
    title: 'F.A.Q.',
    link: 'https://docs.hubbleprotocol.io/faq/general',
    absolute: true,
  },
  {
    title: 'Community',
    link: '/community',
  },
  {
    title: 'Why USDH',
    link: 'https://docs.hubbleprotocol.io/why-use-hubble',
    absolute: true,
  },
  {
    title: 'GitBook',
    link: 'https://docs.hubbleprotocol.io/',
    absolute: true,
  },
];

interface Props {
  scroll: string;
}

const Header: FC<Props> = ({ scroll }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const [click, setClick] = useState<boolean>(false);
  const isBannerHide = true;

  const menu = (
    <Menu>
      {aboutItems.map((item, index) => (
        <Menu.Item key={index} onClick={() => setIsOpenDropdown(false)}>
          {item.absolute ? (
            <a href={item.link} target="_blank" rel="noreferrer">
              {item.title}
            </a>
          ) : (
            <Link to={item.link}>{item.title}</Link>
          )}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleClick = () => setClick(!click);

  return (
    <header className={scroll}>
      {!isBannerHide && <IDOBanner />}

      <div className={`header ${!isBannerHide ? 'banner-show' : ''}`}>
        <div className="header-center">
          <div className="header-space-between">
            <Link to="/" className="header-logo">
              <img src={Logo} alt="logo" loading="lazy" className="desktop" />

              <img
                src={LogoMobile}
                alt="logo"
                loading="lazy"
                style={{ width: '108px', height: '28px' }}
                className="mobile"
              />
            </Link>

            <nav className={`header-nav ${click ? 'active' : ''}`}>
              <div className="header-scroll">
                <div className="header-mobile-style">
                  <ul className="menu-list">
                    <li className="header-dropdown-desktop">
                      <Dropdown
                        overlay={menu}
                        className="nav-item"
                        onVisibleChange={(visible: boolean) => {
                          setIsOpenDropdown(visible);
                        }}
                      >
                        <div
                          className="ant-dropdown-link"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          About{' '}
                          <Arrow
                            style={{
                              marginLeft: '7px',
                              transform: `${isOpenDropdown ? 'rotate(180deg)' : 'rotate(0deg)'}`,
                            }}
                          />
                        </div>
                      </Dropdown>
                    </li>
                    <li onClick={() => setIsOpenDropdown(!isOpenDropdown)} className="header-dropdown-mobile">
                      <div className="header-dropdown-title">
                        About{' '}
                        <Arrow
                          style={{
                            transform: `${isOpenDropdown ? 'rotate(180deg)' : 'rotate(0deg)'}`,
                          }}
                        />
                      </div>
                      {isOpenDropdown && (
                        <ul className="header-dropdown-container">
                          {aboutItems.map((item) => (
                            <li
                              onClick={() => {
                                setClick(false);
                              }}
                            >
                              {item.absolute ? (
                                <a href={item.link} target="_blank" rel="noreferrer">
                                  {item.title}
                                </a>
                              ) : (
                                <Link to={item.link}>{item.title}</Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                    <li>
                      <Link
                        to="/tokens"
                        onClick={() => {
                          setClick(false);
                        }}
                        className="color-white"
                      >
                        Tokens
                      </Link>
                    </li>
                    {/*<li>*/}
                    {/*  <Link*/}
                    {/*    to="/careers"*/}
                    {/*    onClick={() => {*/}
                    {/*      setClick(false);*/}
                    {/*    }}*/}
                    {/*    className="color-white"*/}
                    {/*  >*/}
                    {/*    Careers*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                  </ul>

                  <ul className="social-list">
                    <li>
                      <a
                        href="https://twitter.com/hubbleprotocol"
                        className="social-link"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Twitter />
                      </a>
                    </li>
                    <li>
                      <a href="https://t.me/hubbleprotocol" className="social-link" target="_blank" rel="noreferrer">
                        <Telegram />
                      </a>
                    </li>
                    <li>
                      <a href="https://discord.com/invite/UMFdXJ8MPm" className="social-link" target="_blank" rel="noreferrer">
                        <Discord />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://blog.hubbleprotocol.io/"
                        className="social-link"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Blog />
                      </a>
                    </li>
                  </ul>
                </div>

                <a
                  href="https://app.hubbleprotocol.io/"
                  className="button button-inverse"
                  target="_blank"
                  rel="noreferrer"
                >
                  Launch App
                </a>
              </div>
            </nav>

            <div className="header-mobile-navigation">
              <a
                href="https://app.hubbleprotocol.io/"
                className="button button-inverse"
                target="_blank"
                rel="noreferrer"
              >
                Launch App
              </a>

              <div
                className={`header-burger ${click ? 'active' : ''}`}
                onClick={() => {
                  setIsOpenDropdown(false);
                  handleClick();
                }}
              >
                <svg className="burger" version="1.1" height="100" width="100" viewBox="0 0 100 100">
                  <path className="line line1" d="M 50,35 H 30" />
                  <path className="line line2" d="M 50,35 H 70" />
                  <path className="line line3" d="M 50,50 H 30" />
                  <path className="line line4" d="M 50,50 H 70" />
                  <path className="line line5" d="M 50,65 H 30" />
                  <path className="line line6" d="M 50,65 H 70" />
                </svg>
                <svg className="x" version="1.1" height="100" width="100" viewBox="0 0 100 100">
                  <path className="line" d="M 34,32 L 66,68" />
                  <path className="line" d="M 66,32 L 34,68" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
