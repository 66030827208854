import '../../pages/Content/Content.less';
import './TextAboutHubble.less';

const TextAboutHubble = () => {
  return (
    <div className={`every-container text-about-hubble`}>
      <p>
        <span>Hubble – </span> borrow against your crypto or multiply your crypto exposure
        <span>. Maximize portfolio efficiency - while deposited, your crypto continues to</span> earn the best yield
        available. <span>Use USDH across the Solana ecosystem.</span>
      </p>
    </div>
  );
};

export default TextAboutHubble;
