import "./AnimationImage.less";
// import AnimImg from "../../assets/App_UI.svg";
// import AppScreen from "../../assets/App - screen.svg";
// import { useCallback, useEffect, useRef, useState } from "react";
// import { optionsIntersectionObserver } from "../../utils/utils";

const AnimationImage = ({ directionScroll }: { directionScroll: null | 'up' | 'down'}) => {

  // Animation image, currently static, maybe will need in the future

  // const animImg: any = useRef(null)
  // const [isStartAnimationScreenContent, setStartAnimationScreenContent] = useState(false);
  // const [isStartAnimationScreen, setStartAnimationScreen] = useState(false);

  // let options = useCallback(() => optionsIntersectionObserver(null,"-10% 0px 0px 0px", [0]), []);

  // const animationImage = (entry: any, observer: any) => {
  //   if (entry[0].isIntersecting) {
  //     setStartAnimationScreenContent(true)

  //     setTimeout(() => {
  //       setStartAnimationScreen(true)
  //     }, 1000)

  //   } else {
  //     setStartAnimationScreenContent(false)
  //     setStartAnimationScreen(false)
  //   }
  // }

  // useEffect(() => {
  //   const current = animImg.current;
  //   let observer = new IntersectionObserver(animationImage, options())
  //   if (current) observer.observe(animImg.current);

  //   return () => {
  //     setStartAnimationScreen(false)
  //     if(current) observer.unobserve(current)
  //   }
  // }, [options])

  return (
    <div className={`every-container images-container`}>
      <div className={`screen-app  screen-app-animation`} />
      <div className={`content-screen-app animation-content-screen-app`} />
      {/* <img className={`screen-app  screen-app-animation`} src={AppScreen} alt="App Screen" />
      <img className={`content-screen-app animation-content-screen-app'}`} src={AnimImg} alt="App Screen" /> */}
    </div>
  );
};

export default AnimationImage;
