import React, { FC } from 'react'
import './styles.less'

interface Props {
  link: string;
  text: string
}

export const TransparentLink: FC<Props> = ({ link, text }) => {
  return (
    <a
    href={link}
    className="transparent-link"
    target="_blank"
    rel="noreferrer"
  >
      { text }
  </a>)
}
