import React, { FC } from 'react';
import './styles.less';
import hbbToken from './assets/hbb-token.svg';
import usdh from './assets/usdh.svg';
import kukoin from './assets/kucoin.svg';
import huobi from './assets/huobi.svg';
import orca from './assets/orca.svg';
import saber from './assets/saber.svg';
import mercurial from './assets/mercurial.svg';
import jupiter from './assets/jupiter.svg';
import Footer from '../../Components/Footer/Footer';
import { TransparentLink } from './TransparentLink';
import Head from '../../Components/Head/Head';

const headData = {
  siteName: 'Hubble Protocol',
  title: 'Hubble Protocol | How to Buy $HBB or $USDH - Available on Jupiter, Orca DEX',
  url: 'https://hubbleprotocol.io/buy',
  description:
    'Looking to buy $HBB coin or $USDH? Here are the best places where to find $HBB and $USDH prices on Solana. $HBB crypto has a total max supply of 100 million tokens.',
  imageAlt: 'Hubble Markets',
  imageUrl: '',
  type: 'website',
  pageTitle: 'Hubble Protocol | How to Buy $HBB or $USDH - Available on Jupiter, Orca DEX',
  pageDescription:
    'Looking to buy $HBB coin or $USDH? Here are the best places where to find $HBB and $USDH prices on Solana. $HBB crypto has a total max supply of 100 million tokens.',
};

export const Buy: FC = () => {
  return (
    <>
      <Head {...headData} />

      <div className="buy-wrapper">
        <div className="buy-layout" />
        <div className="buy-content">
          <div className="buy-title floated-element">Buy HBB or USDH</div>

          <div className="buy-blocks">
            <div className="buy-item first-block">
              <div className="buy-card-content">
                <div className="card-header floated-element">
                  <img src={hbbToken} alt="HBB token" />
                  HBB Token
                </div>
                <div className="card-description floated-element">
                  HBB is the governance and utility token of Hubble Protocol. HBB is issued to incentivise behaviours
                  that ensure USDH is liquid and trading at peg. Some of the fees generated from USDH issuance
                  activities are redirected to the staking Vault. In the future, HBB will also be used to vote on
                  community proposals.
                </div>
                <TransparentLink link="https://docs.hubbleprotocol.io/faq/hbb-token-and-staking" text="Learn more" />
              </div>
              <div className="buy-card-divider" />
              <div className="buy-card-content buy-platforms">
                <div className="card-header floated-element">Available on:</div>
                <a href="https://www.kucoin.com/trade/HBB-USDT" target="_blank" rel="noreferrer">
                  <img src={kukoin} alt="Kucoin" />
                </a>
                <a href="https://www.huobi.com/en-us/exchange/hbb_usdt" target="_blank" rel="noreferrer">
                  <img src={huobi} alt="Huobi Global" />
                </a>
                <a href="https://www.orca.so/" target="_blank" rel="noreferrer">
                  <img src={orca} alt="Orca" />
                </a>
              </div>
            </div>

            <div className="buy-item">
              <div className="buy-card-content">
                <div className="card-header floated-element">
                  <img src={usdh} alt="USDH" className="usdh-icon" />
                  USDH
                </div>
                <div className="card-description floated-element">
                  USDH is a crypto-backed, over-collateralized stablecoin. Holders can stake USDH in the Hubble
                  Stability Pool to participate in liquidations or farm HBB rewards.
                </div>
                <TransparentLink link="https://docs.hubbleprotocol.io/faq/usdh-stablecoin" text="Learn more" />
              </div>
              <div className="buy-card-divider" />
              <div className="buy-card-content buy-platforms">
                <div className="card-header floated-element">Buy on:</div>
                <a
                  href="https://app.saber.so/#/swap?from=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&to=USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={saber} alt="Saber" />
                </a>
                <a href="https://www.mercurial.finance/swap/USDC-USDH" target="_blank" rel="noreferrer">
                  <img src={mercurial} alt="Mercurial" />
                </a>
                <a href="https://jup.ag/swap/USDC-USDH" target="_blank" rel="noreferrer">
                  <img src={jupiter} alt="Jupiter" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
