import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { InputWithButton } from '../InputWithButton/InputWithButton';
import Logo from '../../assets/logo.svg';
import { optionsIntersectionObserver } from '../../utils/utils';
import "./Footer.less";

const Footer = ({ setHideShadowBg }: { setHideShadowBg?: (isHide: boolean) => void }) => {
  const footerRef = useRef<HTMLDivElement | null>(null)

  let options = useCallback(() => {
    return optionsIntersectionObserver(null, "0px 0px 0px 0px", [0.1]);
  }, []);

  const footerHideShadow = useCallback(
    (entry: any, observer: any) => {
      if (setHideShadowBg) {
        if (entry[0].isIntersecting) {
          setHideShadowBg(true)
        } else {
          setHideShadowBg(false)
        }
      }
    },
    [setHideShadowBg]
  );

  useEffect(() => {
    if (setHideShadowBg) {
      const current = footerRef.current;
      let observer = new IntersectionObserver(footerHideShadow, options());
      if (current) observer.observe(current);

      return () => {
        if (current) observer.unobserve(current);
      };
    }
  }, [
    options,
    footerHideShadow,
    setHideShadowBg
  ]);

  return (
    <footer className="footer" ref={footerRef}>
      <div className="footer-center">
        <Link to="/" className="footer-logo">
          <img src={Logo} alt="logo" loading="lazy" />
        </Link>

        <div className="footer-space-between">
          <div className="footer-subscribe">
            <Link to="/" className="footer-logo footer-logo-mobile">
              <img src={Logo} alt="logo" loading="lazy" />
            </Link>

            <div className="footer-subscribe-mobile">
              <div className="footer-input">
                <InputWithButton />
              </div>

              <div className="footer-social">
              <a href="https://github.com/hubbleprotocol"
                   style={{ color: "white" }} target="_blank"
                   rel="noreferrer">Github</a>

                <a href="https://twitter.com/hubbleprotocol"
                   style={{ color: "white" }} target="_blank"
                   rel="noreferrer">Twitter</a>

                <a href="https://t.me/hubbleprotocol"
                   style={{ color: "white" }} target="_blank"
                   rel="noreferrer">Telegram</a>

                <a href="https://discord.com/invite/UMFdXJ8MPm"
                   style={{ color: "white" }} target="_blank"
                   rel="noreferrer">Discord</a>

                <a href="https://blog.hubbleprotocol.io/"
                   style={{ color: "white" }} target="_blank"
                   rel="noreferrer">Blog</a>
              </div>
            </div>
          </div>
          <div className="footer-menu">
            <Link to="/" className="footer-logo footer-logo-table">
              <img src={Logo} alt="logo" loading="lazy" />
            </Link>

            {/*<ul>
              <li className="footer-menu-title">For Developers</li>
              <li className="footer-letter">Code base</li>
              <li className="footer-letter">Technical Resources</li>
              <li className="footer-letter">SDK</li>
              <li className="footer-letter">Bug Bounty</li>
              </ul>*/}

            <ul>
              <li className="footer-menu-title">Company</li>
              <li className="footer-menu-link" style={{ display: "none" }}>Contact Us</li>
              {/*<li className="footer-menu-link">*/}
              {/*  <a href="https://docs.google.com/document/d/1uyZp1j-h6J9RAO7M-xukhR-gxWKMZuRwNIShDqrvBCs/edit"*/}
              {/*     target="_blank" rel="noreferrer">*/}
              {/*    Press Kit*/}
              {/*  </a>*/}
              {/*</li>*/}
            </ul>

            <ul>
              <li className="footer-menu-title">Product</li>
              <li className="footer-menu-link">
                <a href="https://docs.hubbleprotocol.io/faq/general"
                   target="_blank" rel="noreferrer">
                  FAQ
                </a>
              </li>
              <li className="footer-menu-link">
                <a href="https://blog.hubbleprotocol.io/"
                   target="_blank" rel="noreferrer">
                  Blog
                </a>
              </li>
              <li className="footer-menu-link">
                <a href="https://app.hubbleprotocol.io/terms"
                   target="_blank" rel="noreferrer">
                  Terms
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
