import React from "react";
import { Helmet } from "react-helmet";

interface HeadProps {
  siteName: string;
  title: string;
  url: string;
  description: string;
  imageUrl: string;
  imageAlt: string;
  type: string;
  pageTitle?: string;
  pageDescription?: string;
}

const Head: React.FC<HeadProps> = ({
  siteName,
  title,
  url,
  description,
  imageUrl,
  imageAlt,
  type,
  pageTitle,
  pageDescription,
}) => {
  return (
    <Helmet>
      {pageTitle && <title>{pageTitle}</title>}
      {pageDescription && <meta content={pageDescription} name="description" />}

      <meta property="og:locale" content="en_GB" />
      {siteName && <meta property="og:site_name" content={siteName} />}
      {title && <meta property="og:title" content={title} />}
      {url && <meta property="og:url" content={url} />}
      {description && <meta property="og:description" content={description} />}
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      {imageAlt && <meta property="og:image:alt" content={imageAlt} />}
      {type && <meta property="og:type" content={type} />}
    </Helmet>
  );
};

export default Head;
