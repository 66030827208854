import { Route, Switch, withRouter, useLocation } from 'react-router-dom';
import React, { Ref, useCallback, useEffect, useRef, useState } from 'react';
import './App.less';
import MainContent from './pages/Content';
// import Careers from './pages/Careers';
import { Modal } from 'antd';
import BannerForm from './pages/BannerForm';
import Blank from './pages/Blank';
import Page404 from './pages/Page404';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Header from './Components/Header/Header';
import { Buy } from './pages/Buy';
import useHotjar from 'react-use-hotjar';
import { Roadmap } from './pages/Roadmap';
import Team from './pages/Team';

function usePageViews() {
  let location = useLocation();
  // do not send pageview at the first run
  // as ga script does it automatically on gtag('config',...
  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    if (window.gtag && typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', {
        page_location: window.location.href,
      });
    }
  }, [location]);
}

const myCustomLogger = console.info;

function App() {
  usePageViews();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isPageScrolled, setPageScrolled] = useState<boolean>(false);

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  const { initHotjar } = useHotjar();

  useEffect(() => {
    window._enzuzocb = window._enzuzocb || [];

    window._enzuzocb.push(() => {
      console.log('consent confirmed');
      initHotjar(3263967, 6, false, myCustomLogger);
    });

    window._haveEnzuzocbSet = true;
  }, [initHotjar]);

  const containerNode: Ref<any> = useRef();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // The scroll listener
  const handleScroll = useCallback((e: React.UIEvent<HTMLElement>) => {
    setPageScrolled(e.currentTarget.scrollTop !== 0);
  }, []);

  useEffect(() => {
    const div = containerNode.current;
    div.addEventListener('scroll', handleScroll);
    return () => {
      div.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // @ts-ignore
  return (
    <div className="App" onScroll={handleScroll} ref={containerNode}>
      <Header scroll={`${isPageScrolled ? 'scrolled' : ''}`} />

      <ScrollToTop>
        <Switch>
          {/*<Route path="/careers/smart-contracts-engineer">*/}
          {/*  <Careers initProfession="Smart contracts engineer" />*/}
          {/*</Route>*/}
          {/*<Route path="/careers/quant-and-derivatives-researcher">*/}
          {/*  <Careers initProfession="Quant and derivatives researcher" />*/}
          {/*</Route>*/}
          {/*<Route path="/careers">*/}
          {/*  <Careers initProfession="" />*/}
          {/*</Route>*/}
          <Route path="/tokens">
            <Buy />
          </Route>
          <Route path="/community">
            <Team />
          </Route>
          <Route path="/whitelist">
            <BannerForm />
          </Route>
          <Route path="/blank">
            <Blank />
          </Route>
          {/*<Route path="/roadmap">*/}
          {/*  <Roadmap />*/}
          {/*</Route>*/}
          <Route path="/" exact={true}>
            <MainContent />
          </Route>
          <Route path="*">
            <Page404 />
          </Route>
        </Switch>
      </ScrollToTop>

      <Modal title="The HBB Token" visible={isModalVisible} onCancel={handleCancel}>
        <p style={{ fontSize: 22 }}>
          HBB is scarcer than Cryptopunks at the moment, but we'll mint more at our IDO! <br />
          <br />
          Subscribe below to find out the date.
        </p>
      </Modal>
    </div>
  );
}

export default withRouter(App);
