import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import ogImg from "../../assets/og-careers.png";
import { Helmet } from "react-helmet";
import "./BannerForm.less";

const headData = {
  siteName: "Hubble Protocol",
  title: "Hubble Protocol",
  url: "https://hubbleprotocol.io/careers",
  description: "Exploring the DeFi Universe on Solana",
  imageUrl: ogImg || "",
  imageAlt: "Hubble Markets",
  type: "website",
};

const BannerForm = () => {
  return (
    <>
      <Head {...headData} />

      <div className="every-container form-banner">
        <div data-tf-widget="rzjShW1q" data-tf-iframe-props="title=IDO form" style={ {width: "100%", height: "100%"}} />
        <Helmet>
          <script src="//embed.typeform.com/next/embed.js"></script>
        </Helmet>
      </div>

      <Footer />
    </>
  );
};

export default BannerForm;
