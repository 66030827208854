import Head from "../../Components/Head/Head";
import Footer from "../../Components/Footer/Footer";
import ogImg from "../../assets/og-careers.png";
import EmptyBlock from "../../Components/EmptyBlock/EmptyBlock";

const headData = {
  siteName: "Hubble Protocol",
  title: "Hubble Protocol",
  url: "https://hubbleprotocol.io/careers",
  description: "Exploring the DeFi Universe on Solana",
  imageUrl: ogImg || "",
  imageAlt: "Hubble Markets",
  type: "website",
};

const Page404 = () => {
  return (
    <>
      <Head {...headData} />

      <EmptyBlock
        title="Oh god!"
        descriptionUp="Looks like a UFO stole this page..."
        descriptionDown="But pages are not teeth, they will grow back"
        buttonText="Check out landing instead"
        widthButton="248px"
        isBlankPage={false}
      />

      <Footer />
    </>
  );
};

export default Page404;
