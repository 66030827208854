export let optionsIntersectionObserver = (
    root: HTMLElement | null,
    rootMargin: string,
    threshold: Array<number>
): {
    root?: HTMLElement | null,
    rootMargin: string,
    threshold: Array<number>
} => {
    return {
      root: root,
      rootMargin: rootMargin,
      threshold: threshold
    }
};