import { Dispatch, SetStateAction, useCallback, useEffect, useLayoutEffect, useRef, useState, } from "react";
import "../../pages/Content/Content.less";
import "./AnimationScrollVideo.less";
import { optionsIntersectionObserver } from "../../utils/utils";

// @ts-ignore
import AnimationScroll from "../../assets/videos/animation.mp4";

// @ts-ignore
import RevertAnimationScroll from "../../assets/videos/revert_video_animation.mp4";

const AnimationScrollVideo = ({
  isDownAnimationVideoWorked,
  setIsDownAnimationVideoWorked,
  isUpAnimationVideoWorked,
  setIsUpAnimationVideoWorked,
  setIsStartUpAnimationVideoWorked,
  directionScroll,
  isMobile
}: {
  isDownAnimationVideoWorked: boolean;
  setIsDownAnimationVideoWorked: Dispatch<SetStateAction<boolean>>;
  isUpAnimationVideoWorked: boolean;
  setIsUpAnimationVideoWorked: Dispatch<SetStateAction<boolean>>;
  setIsStartUpAnimationVideoWorked: Dispatch<SetStateAction<boolean>>;
  directionScroll: null | "up" | "down";
  isMobile: boolean;
}) => {


  const downAnimationVideo: any = useRef(null);
  const upAnimationVideo: any = useRef(null);
  const containerVideo: any = useRef(null);

  const [isLoopVideo, setLoopVideo] = useState(true);
  const [isVisibleDown, setIsVisibleDown] = useState(false);
  const [isVisibleUp, setIsVisibleUp] = useState(false);
  const [windowScrollY, setWindowScrollY] = useState(0);

  let options = useCallback(() => {
    return optionsIntersectionObserver(null, "0px 0px 0px 0px", [0.5]);
  }, []);

  const animationVideoScroll = useCallback(
    (entry: any, observer: any) => {
      if ((Math.floor(window.innerHeight / 12) >= windowScrollY)) {
        setIsVisibleDown(true);
      } else {
        setIsVisibleDown(false);
      }

      if (
        entry[0].isIntersecting &&
        windowScrollY < 200 &&
        directionScroll === "up"
      ) {
        setIsVisibleUp(true);
      } else {
        setIsVisibleUp(false);
      }
    },
    [windowScrollY, directionScroll]
  );

  const handleTimeUpdate = () => {

    const downAnimItem = downAnimationVideo.current;
    const upAnimItem = upAnimationVideo.current;

    if (isMobile) {
      if (downAnimItem.currentTime.toFixed(2) > 5.45) {
        downAnimItem.currentTime = 0;
      }
    } else {
      if (
        directionScroll === "down" ||
        !directionScroll ||
        isUpAnimationVideoWorked
      ) {
        if (downAnimItem.currentTime.toFixed(2) > 7.45) {
          downAnimItem.currentTime = 0;
          downAnimItem.pause();
          upAnimItem.play();

          containerVideo.current.classList.add("hide-block");
          document.body.classList.remove("disable-overflow");
          document.documentElement.classList.remove('disable-overflow')

          setLoopVideo(true);
          setIsUpAnimationVideoWorked(false);
        } else if (
          isVisibleDown &&
          !isDownAnimationVideoWorked &&
          directionScroll === "down"
        ) {
          document.body.classList.add("disable-overflow");
          document.documentElement.classList.add('disable-overflow')

          downAnimItem.currentTime = 6;

          setIsStartUpAnimationVideoWorked(false);
          setLoopVideo(false);
          setIsDownAnimationVideoWorked(true);
        } else if (
          downAnimItem.currentTime.toFixed(2) > 5.45 &&
          isLoopVideo &&
          !isDownAnimationVideoWorked
        ) {
          downAnimItem.currentTime = 0;
        }
      } else {
        // if work revert animation
        if (isVisibleUp && !isUpAnimationVideoWorked && isLoopVideo) {
          upAnimItem.currentTime = 0;
          containerVideo.current.classList.remove("hide-block");
          document.body.classList.add("disable-overflow");
          document.documentElement.classList.add('disable-overflow')

          setLoopVideo(false);
          setIsStartUpAnimationVideoWorked(true);
        } else if (
          upAnimItem.currentTime.toFixed(2) > 1.78 &&
          !isUpAnimationVideoWorked &&
          !isLoopVideo
        ) {
          setIsUpAnimationVideoWorked(true);
          setIsDownAnimationVideoWorked(false);
          setLoopVideo(true);

          upAnimItem.pause();
          downAnimItem.play();

          document.body.classList.remove("disable-overflow");
          document.documentElement.classList.remove('disable-overflow')
        }
      }
    }
  };

  useLayoutEffect(() => {
    if (!isMobile) {
      const current = containerVideo.current;
      let observer = new IntersectionObserver(animationVideoScroll, options());
      if (current) observer.observe(containerVideo.current);

      return () => {
        if (current) observer.unobserve(current);
      };
    }
  }, [
    options,
    isVisibleDown,
    isVisibleUp,
    directionScroll,
    animationVideoScroll,
    isMobile
  ]);

  const scrollListener = () => {
    setWindowScrollY(window.scrollY);
  };

  useLayoutEffect(() => {
    if (!isMobile) {
      window.addEventListener("scroll", scrollListener);
      return () => window.removeEventListener("scroll", scrollListener);
    }
  }, [isMobile]);

  useEffect(() => {
    downAnimationVideo.current.play()
  }, [])

  return (
    <div
      className={ `every-container container-animation-video` }
      ref={ containerVideo }
    >
      <video
        className={ `${ !isUpAnimationVideoWorked ? "hide-video animation-video": "video" }` }
        src={ AnimationScroll }
        muted={ true }
        ref={ downAnimationVideo }
        onTimeUpdate={ handleTimeUpdate }
        playsInline={ true }
        preload="auto"
      />
      <video
        className={ `${ isUpAnimationVideoWorked && "hide-video animation-video"
        }` }
        src={ RevertAnimationScroll }
        muted={ true }
        ref={ upAnimationVideo }
        loop={ true }
        onTimeUpdate={ handleTimeUpdate }
        playsInline={ true }
      />
    </div>
  );
};

export default AnimationScrollVideo;
